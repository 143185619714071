(function ($) {
  $(window).load(function() {
    $('.eyecatcher .owl-carousel').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      
      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,
      
      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      dots: true,
      navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],

      // drag options
      mouseDrag: false,
      touchDrag: false
    });

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      dots: true,
      navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],

      dots: false,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });
  });

  $(document).ready(function(){

  //   smoothscroll
  $('.location_accommodation_detail .goto-prices, .accommodation_detail .goto-prices').click(function(e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $('.extra-info').offset().top - 0
    }, '');
  });


    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    $('.collapse-title').click(function() {
      $(this).parent().find('.collapse-content').slideToggle();
    });

  });

 
})(jQuery);


